import React from 'react';
import Header from '../components/about_us/Header'
import KPISection from '../components/about_us/KPISection';
import WhyMatters from '../components/about_us/WhyMatters';
import Benefits from '../components/about_us/Benefits';
import HowItWorks from '../components/about_us/HowItWorks';
import MissionVisionPurpose from '../components/about_us/MissionVisionPurpose';
import KeyFeatures from '../components/about_us/KeyFeatures';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <MissionVisionPurpose />
      <HowItWorks />
      <KeyFeatures />
      <Benefits />
      <WhyMatters />
      <KPISection />
      <Header />
    </div>
  );
};

export default AboutUs;
