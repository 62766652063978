import React from 'react';
import './KPISection.css';
import accountabilityIcon from '../../assets/accountability.png'; // Sustituye con tu icono
import performanceIcon from '../../assets/performance.png'; // Sustituye con tu icono
import scalabilityIcon from '../../assets/scalability.png'; // Sustituye con tu icono

const KPISection = () => {
  return (
    <section className="measure-section">
      <h2>Measure | Every Drop Is Counted</h2>
      <div className="measure-cards-container">
        <div className="measure-card">
          <img src={accountabilityIcon} alt="Accountability Icon" />
          <h3>ACCOUNTABILITY</h3>
          <p>KPI - Participation Rate</p>
          <p>
            Measure % of business actively participating in the Xchange compared to
            total eligible businesses per launch sector
          </p>
          <p>KPI - Reporting Accuracy</p>
          <p>
            Quantify number of tracking errors in company reporting versus actual use
          </p>
        </div>
        <div className="measure-card">
          <img src={performanceIcon} alt="Performance Icon" />
          <h3>PERFORMANCE</h3>
          <p>KPI - Customer Satisfaction</p>
          <p>
            Measure fairness and usability of the AquaXchange system via partner
            surveys
          </p>
          <p>KPI - Regulatory Satisfaction</p>
          <p>
            Conduct meetings with government and aigües to review the system's impact
            on Barcelona's water usage, accountability, and environmental compliance
          </p>
        </div>
        <div className="measure-card">
          <img src={scalabilityIcon} alt="Scalability Icon" />
          <h3>SCALABILITY</h3>
          <p>KPI - System Growth</p>
          <p>
            Measure rate of new participants and partners of AquaXchange
          </p>
        </div>
      </div>
    </section>
  );
};

export default KPISection;
