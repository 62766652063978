import React from 'react';
import './Header.css';
import ari from '../../assets/ariHD.jpg';
import ash from '../../assets/ashHD.jpg';
import fer from '../../assets/fernandoHD.jpg';
import joan from '../../assets/joanHD.jpg';
import julia from '../../assets/juliaHD.jpg';
import tiya from '../../assets/tiyaHD.jpg';

const Header = () => {
  return (
    <section className="section-header">
      <h1 className="title">
        Passion | DROP is making Barcelona business profitable & Sustainable
      </h1>
      <div className="team">
        <div className="row">
          <div className="member">
            <img src={fer} alt="Fernando Barron" />
            <h3>Fernando Barron</h3>
            <p>Founder and Business Lead</p>
            <p>ESADE Alumni</p>
          </div>
          <div className="member">
            <img src={ash} alt="Ashlee Mew" />
            <h3>Ashlee Mew</h3>
            <p>Founder and Design Lead</p>
            <p>IED Alumni</p>
          </div>
          <div className="member">
            <img src={joan} alt="Joan Camps" />
            <h3>Joan Camps</h3>
            <p>Founder and Tech Lead</p>
            <p>UPC Alumni</p>
          </div>
        </div>
        <div className="row">
          <div className="member">
            <img src={julia} alt="Julia Fach" />
            <h3>Julia Fach</h3>
            <p>Founder and Business Lead</p>
            <p>ESADE Alumni</p>
          </div>
          <div className="member">
            <img src={tiya} alt="Tiya Titus" />
            <h3>Tiya Titus</h3>
            <p>Founder and Design Lead</p>
            <p>IED Alumni</p>
          </div>
          <div className="member">
            <img src={ari} alt="Ariadna Marcos" />
            <h3>Ariadna Marcos</h3>
            <p>Founder and Tech Lead</p>
            <p>UPC Alumni</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
