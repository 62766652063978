import React, { useState } from "react";
import MarketHeader from "../components/simulation/MarketHeader";
import TradingSection from "../components/simulation/TradingSection";
import OrderBook from "../components/simulation/OrderBook";
import MarketTrades from "../components/simulation/MarketTrades";
import TradingForm from "../components/simulation/TradingForm";
import CompanySelector from "../components/simulation/CompanySelector";

function Simulation() {
  // Estado para las empresas seleccionadas
  const [originCompany, setOriginCompany] = useState("Company A");
  const [destinationCompany, setDestinationCompany] = useState("Company B");

  // Base de datos ficticia de transacciones
  const transactions = [
    { id: 1, origin: "Company A", destination: "Company B", quantity: 100, price: 5, date: "2024-11-28 12:30:01" },
    { id: 2, origin: "Company C", destination: "Company A", quantity: 50, price: 6, date: "2024-11-25 10:15:32" },
    { id: 3, origin: "Company B", destination: "Company D", quantity: 200, price: 4.5, date: "2024-11-20 14:45:12" },
    { id: 4, origin: "Company A", destination: "Company C", quantity: 80, price: 5.8, date: "2024-11-15 09:05:45" },
    { id: 5, origin: "Company D", destination: "Company B", quantity: 150, price: 4.3, date: "2024-11-10 16:20:11" },
    { id: 6, origin: "Company A", destination: "Company E", quantity: 120, price: 5.1, date: "2024-10-30 11:12:00" },
    { id: 7, origin: "Company E", destination: "Company C", quantity: 90, price: 6.2, date: "2024-10-25 13:35:25" },
    { id: 8, origin: "Company B", destination: "Company A", quantity: 70, price: 5.6, date: "2024-10-20 08:45:13" },
    { id: 9, origin: "Company C", destination: "Company D", quantity: 110, price: 4.7, date: "2024-10-15 15:50:09" },
    { id: 10, origin: "Company D", destination: "Company E", quantity: 140, price: 4.9, date: "2024-10-10 10:10:10" },
    { id: 11, origin: "Company A", destination: "Company B", quantity: 180, price: 5.2, date: "2024-09-30 12:40:20" },
    { id: 12, origin: "Company B", destination: "Company C", quantity: 60, price: 6.0, date: "2024-09-25 14:25:30" },
    { id: 13, origin: "Company C", destination: "Company A", quantity: 100, price: 5.4, date: "2024-09-20 13:15:05" },
    { id: 14, origin: "Company D", destination: "Company B", quantity: 190, price: 4.8, date: "2024-09-15 11:35:40" },
    { id: 15, origin: "Company E", destination: "Company D", quantity: 130, price: 4.6, date: "2024-09-10 16:55:33" },
    { id: 16, origin: "Company A", destination: "Company C", quantity: 90, price: 5.7, date: "2024-09-05 12:20:00" },
    { id: 17, origin: "Company C", destination: "Company E", quantity: 150, price: 6.1, date: "2024-08-30 15:45:12" },
    { id: 18, origin: "Company B", destination: "Company A", quantity: 200, price: 5.3, date: "2024-08-25 10:10:55" },
    { id: 19, origin: "Company E", destination: "Company B", quantity: 140, price: 4.4, date: "2024-08-20 09:00:00" },
    { id: 20, origin: "Company D", destination: "Company A", quantity: 170, price: 4.9, date: "2024-08-15 14:50:20" },
    { id: 21, origin: "Company C", destination: "Company D", quantity: 60, price: 4.6, date: "2024-08-10 11:30:45" },
    { id: 22, origin: "Company A", destination: "Company E", quantity: 120, price: 5.0, date: "2024-08-05 13:05:25" },
    { id: 23, origin: "Company B", destination: "Company C", quantity: 80, price: 6.3, date: "2024-07-30 15:20:10" },
    { id: 24, origin: "Company D", destination: "Company E", quantity: 100, price: 4.7, date: "2024-07-25 14:15:00" },
    { id: 25, origin: "Company E", destination: "Company A", quantity: 130, price: 5.2, date: "2024-07-20 10:10:10" },
  ];
  

  return (
    <div
      style={{
        background: "#faf3e0", // Fondo cálido y suave
        color: "#333", // Texto oscuro para contraste
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column", // Cabecera en la parte superior
        gap: "20px",
        padding: "20px",
      }}
    >
      {/* Encabezado */}
      <MarketHeader />

      {/* Contenedor principal */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "3fr 1fr", // Gráfico más ancho que el resto
          gap: "20px",
          alignItems: "start", // Alineación superior
        }}
      >
        {/* Gráfico principal y Coin Overview */}
        <TradingSection transactions = {transactions}/>

        {/* Contenedor lateral: CompanySelector y TradingForm */}
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <CompanySelector
            originCompany={originCompany}
            destinationCompany={destinationCompany}
            setOriginCompany={setOriginCompany}
            setDestinationCompany={setDestinationCompany}
          />

          <TradingForm currentPrice={5.2}/>
        </div>
      </div>

      {/* Contenedor inferior: OrderBook y MarketTrades */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr", // OrderBook más ancho que MarketTrades
          gap: "20px",
          alignItems: "start",
        }}
      >
        <OrderBook
          originCompany={originCompany}
          destinationCompany={destinationCompany}
          transactions={transactions}
        />
         <MarketTrades transactions = {transactions}/>
        
      </div>
    </div>
  );
}

export default Simulation;
