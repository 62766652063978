import React, { useEffect, useState } from 'react';
import HeroSection from '../components/landing_page/HeroSection';

function Home() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Efectos visuales (no afectan el flujo del diseño)
  const heroStyle = {
    transform: `scale(${1 + scrollY / 1000})`,
  };

 

  return (
    <>
      <div className="first-section">
        <div className="first-content" style={heroStyle}>
          <HeroSection />
        </div>
      </div>


    </>
  );
}

export default Home;
