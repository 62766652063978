import React, { useState, useEffect } from "react";

const TradingForm = ({ currentPrice }) => {
  const [quantity, setQuantity] = useState("");
  const [total, setTotal] = useState("");

  // Actualiza el total automáticamente cuando cambian el precio o la cantidad
  useEffect(() => {
    setTotal((currentPrice * quantity).toFixed(2));
  }, [currentPrice, quantity]);

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleTransaction = (type) => {
    alert(`${type} Order Placed: ${quantity} AQUA at ${currentPrice} EUR`);
    // Aquí puedes integrar la lógica de transacción
  };

  return (
    <div
      style={{
        background: "#1f1f1f",
        padding: "20px",
        borderRadius: "10px",
        color: "#fff",
        fontFamily: "Arial, sans-serif",
      }}
    >
      {/* Precio no editable */}
      <div style={{ marginTop: "20px" }}>
        <label style={{ display: "block", marginBottom: "5px" }}>Price (EUR)</label>
        <input
          type="text"
          value={currentPrice} // Refleja el precio actual
          readOnly
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #333",
            background: "#1f1f1f",
            color: "#fff",
          }}
        />
      </div>

      {/* Cantidad editable */}
      <div style={{ marginTop: "20px" }}>
        <label style={{ display: "block", marginBottom: "5px" }}>Quantity (AQUA)</label>
        <input
          type="number"
          value={quantity}
          onChange={handleQuantityChange}
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #333",
            background: "#121212",
            color: "#fff",
          }}
        />
      </div>

      {/* Total no editable */}
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <label style={{ display: "block", marginBottom: "5px" }}>Total (EUR)</label>
        <input
          type="text"
          value={total}
          readOnly
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #333",
            background: "#1f1f1f",
            color: "#fff",
          }}
        />
      </div>

      {/* Botones de Confirmar */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          onClick={() => handleTransaction("Buy")}
          style={{
            flex: 1,
            margin: "5px",
            backgroundColor: "#14b8a6",
            border: "none",
            padding: "10px",
            color: "#fff",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Confirm Buy
        </button>
        <button
          onClick={() => handleTransaction("Sell")}
          style={{
            flex: 1,
            margin: "5px",
            backgroundColor: "#ef4444",
            border: "none",
            padding: "10px",
            color: "#fff",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Confirm Sell
        </button>
      </div>
    </div>
  );
};

export default TradingForm;
