import React from "react";

const OrderBook = ({ originCompany, destinationCompany, transactions }) => {
  // Filtrar transacciones por las empresas seleccionadas
  const filteredTransactions = transactions.filter(
    (tx) =>
      tx.origin === originCompany && tx.destination === destinationCompany
  );

  return (
    <div
      style={{
        background: "#1f1f1f",
        color: "#fff",
        padding: "20px",
        borderRadius: "10px",
        fontFamily: "Arial, sans-serif",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Título */}
      <h3
        style={{
          color: "#4caf50",
          textAlign: "center",
          marginBottom: "15px",
          borderBottom: "2px solid #4caf50",
          paddingBottom: "10px",
        }}
      >
        Order Book
      </h3>

      {/* Tabla de Transacciones */}
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ textAlign: "left", color: "#aaa", padding: "10px 5px" }}>Origin</th>
            <th style={{ textAlign: "left", color: "#aaa", padding: "10px 5px" }}>Destination</th>
            <th style={{ textAlign: "left", color: "#aaa", padding: "10px 5px" }}>Quantity</th>
            <th style={{ textAlign: "left", color: "#aaa", padding: "10px 5px" }}>Price</th>
            <th style={{ textAlign: "left", color: "#aaa", padding: "10px 5px" }}>Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransactions.map((tx) => (
            <tr
              key={tx.id}
              style={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              }}
            >
              <td style={{ padding: "10px 5px", color: "#fff" }}>{tx.origin}</td>
              <td style={{ padding: "10px 5px", color: "#fff" }}>{tx.destination}</td>
              <td style={{ padding: "10px 5px", color: "#fff" }}>{tx.quantity}</td>
              <td style={{ padding: "10px 5px", color: "#fff" }}>{tx.price}€</td>
              <td style={{ padding: "10px 5px", color: "#aaa" }}>{tx.date}</td>
            </tr>
          ))}
          {filteredTransactions.length === 0 && (
            <tr>
              <td colSpan="5" style={{ textAlign: "center", padding: "10px", color: "#aaa" }}>
                No transactions found for the selected companies.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OrderBook;
