import React from 'react';
import './WhyMatters.css';

const WhyMatters = () => {
  return (
    <section className="why-matters-section">
      <div className="why-matters-content">
        <h2>Why I Every Drop Matters</h2>
        <div className="stats-container">
          <div className="stat">
            <h3>23%</h3>
            <p>Water consumption comes from businesses</p>
          </div>
          <div className="stat">
            <h3>20%</h3>
            <p>
              Of Spain's industrial output and 25% of Spanish exports come from
              Barcelona's manufacturing industry
            </p>
          </div>
          <div className="stat">
            <h3>25%</h3>
            <p>
              Barcelona's government sets a standard restriction on their usage
              (based on annual consumption) during drought periods
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyMatters;
