import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './MenuButtons.css';

const MenuButtons = () => {
  const location = useLocation();

  // Aplica un color sólido si estamos en /about-us
  const menuBackgroundColor =
    location.pathname === '/about-us' ? '#00274d' : 'transparent'; // Color sólido para About Us

  return (
    <div
      className="menu-buttons-container"
      style={{ backgroundColor: menuBackgroundColor }}
    >
      <NavLink to="/" className="menu-button">
        Home
      </NavLink>
      <NavLink to="/about-us" className="menu-button">
        About Us
      </NavLink>
      <NavLink to="/contact" className="menu-button">
        Contact Us
      </NavLink>
    </div>
  );
};

export default MenuButtons;
