import React from 'react';
import './KeyFeatures.css';
import featureIcon1 from '../../assets/featureIcon1.png';
import featureIcon2 from '../../assets/featureIcon2.png';
import featureIcon3 from '../../assets/featureIcon3.png';

const KeyFeatures = () => {
  const features = [
    {
      icon: featureIcon1,
      title: "Reinvestment in water sourcing and renewables",
      description: [
        "Promote water conservation",
        "Create proactive resource management tool",
      ],
    },
    {
      icon: featureIcon2,
      title: "Credit-based values in sharing water consumption equity",
      description: [
        "Credit exchange system based on water supply and demand factors from allocation",
        "Temporary water trade - annual water trade allocation in season based on rainfall, drought period, and basin capacity",
      ],
    },
    {
      icon: featureIcon3,
      title: "Decrease business operations costs",
      description: [
        "Minimisation reliance and depletion of Barcelona’s reservoir in the drought period",
        "Reducing water waste and inefficiency",
      ],
    },
  ];

  return (
    <section className="key-features">
      <h2>Key Features | Trade your Drops</h2>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div className="feature-card" key={index}>
            <img src={feature.icon} alt={`Feature ${index + 1}`} />
            <h3>{feature.title}</h3>
            <ul className='desctiption'>
              {feature.description.map((desc, idx) => (
                <li key={idx}>{desc}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default KeyFeatures;
