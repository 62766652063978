import React from 'react';
import './HowItWorks.css';
import aigues from '../../assets/aigues.png';

const HowItWorks = () => {
    return (
        <section className="section-how">
            <div className="whiteblock">
                <div className="textblock">
                    <h2>How It Works</h2>
                    <p className="content">
                        Drop is partnering with manufacturing businesses across Barcelona to
                        create a water exchange eco-system during drought periods.
                    </p>
                    <p className="content">
                        Regulated in collaborations with government agencies who set industrial
                        limitations and Aigües Barcelona’s smart water tracking meters.
                    </p>
                    <p className="content">
                        Actively partnering with Barcelona-based manufacturing organisations to
                        exchange water over/under use for 50% profit and 50% profit
                        reinvestment in sustainable water practices such as desalination.
                    </p>
                    <p className="content">
                        These exchanges will occur during drought periods with companies (who
                        consumed less) receiving profit from a business (who consumed more)
                        requesting to account for their overconsumption with the other businesses’
                        water savings.
                    </p>
                </div>
                <div className="imageblock">
                    <img src={aigues} alt="Aigües de Barcelona" />
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
