import React, { useState } from "react";

const CompanySelector = () => {
  const companies = ["Company A", "Company B", "Company C", "Company D", "Company E"];
  const [originCompany, setOriginCompany] = useState("Select Origin");
  const [destinationCompany, setDestinationCompany] = useState("Select Destination");
  const [showOriginDropdown, setShowOriginDropdown] = useState(false);
  const [showDestinationDropdown, setShowDestinationDropdown] = useState(false);

  const handleOriginSelect = (company) => {
    setOriginCompany(company);
    setShowOriginDropdown(false);
  };

  const handleDestinationSelect = (company) => {
    setDestinationCompany(company);
    setShowDestinationDropdown(false);
  };

  return (
    <div
      style={{
        background: "#1f1f1f",
        color: "#fff",
        padding: "20px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <h4 style={{
          color: "#4caf50",
          marginBottom: "10px",
          textAlign: "center",
          borderBottom: "2px solid #4caf50",
          paddingBottom: "5px",
        }}
      >Company Selector</h4>
      <button
        onClick={() => setShowOriginDropdown(!showOriginDropdown)}
        style={{
          background: "#333",
          color: "#fff",
          padding: "10px",
          border: "1px solid #4caf50",
          borderRadius: "5px",
        }}
      >
        {originCompany}
      </button>
      {showOriginDropdown && (
        <ul style={{ background: "#333", listStyle: "none", padding: "10px" }}>
          {companies.map((company) => (
            <li
              key={company}
              onClick={() => handleOriginSelect(company)}
              style={{ cursor: "pointer", padding: "5px" }}
            >
              {company}
            </li>
          ))}
        </ul>
      )}
      <button
        onClick={() => setShowDestinationDropdown(!showDestinationDropdown)}
        style={{
          background: "#333",
          color: "#fff",
          padding: "10px",
          border: "1px solid #ef4444",
          borderRadius: "5px",
        }}
      >
        {destinationCompany}
      </button>
      {showDestinationDropdown && (
        <ul style={{ background: "#333", listStyle: "none", padding: "10px" }}>
          {companies.map((company) => (
            <li
              key={company}
              onClick={() => handleDestinationSelect(company)}
              style={{ cursor: "pointer", padding: "5px" }}
            >
              {company}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CompanySelector;
