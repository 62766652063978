import React, { useState } from "react";
import TradingChart from "./TradingChart";

const CoinOverview = () => {
  return (
    <div>
      <h3 style={{ color: "#4caf50", fontWeight: "bold", marginBottom: "10px" }}>
        Coin Overview
      </h3>
      <p style={{ color: "#aaa" }}>
        This section contains detailed information about the cryptocurrency Aqua Exchange (AQUA).
      </p>
      <ul style={{ color: "#fff", lineHeight: "1.6" }}>
        <li>Symbol: AQUA</li>
        <li>Max Total Supply: 1,000,000,000 AQUA</li>
        <li>Holders: 1</li>
        <li>Total Transfers: 1</li>
        <li>Contract Address: <a href="https://etherscan.io/address/0xb120f889a467610819f04e26447e209c00fbeeb2" style={{ color: "#4caf50" }}>0xb120f889a467610819f04e26447e209c00fbeeb2</a></li>
        <li>Liquidity Pool: 0.029 ETH for 1,000,000 AQUA</li>
        <li>Circulating Market Cap: $0.00</li>
      </ul>
    </div>
  );
};


const TradingSection = ({transactions}) => {
  const [activeTab, setActiveTab] = useState("chart");

  return (
    <div
      style={{
        background: "#1f1f1f",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Barra de pestañas */}
      <div style={{ display: "flex", borderBottom: "1px solid #333", marginBottom: "10px" }}>
        <button
          onClick={() => setActiveTab("chart")}
          style={{
            flex: 1,
            padding: "10px",
            background: activeTab === "chart" ? "#121212" : "transparent",
            color: activeTab === "chart" ? "#fff" : "#aaa",
            border: "none",
            borderBottom: activeTab === "chart" ? "2px solid #4caf50" : "none",
            cursor: "pointer",
          }}
        >
          Chart
        </button>
        <button
          onClick={() => setActiveTab("overview")}
          style={{
            flex: 1,
            padding: "10px",
            background: activeTab === "overview" ? "#121212" : "transparent",
            color: activeTab === "overview" ? "#fff" : "#aaa",
            border: "none",
            borderBottom: activeTab === "overview" ? "2px solid #4caf50" : "none",
            cursor: "pointer",
          }}
        >
          Coin Overview
        </button>
      </div>

      {/* Contenido de las pestañas */}
      <div
        style={{
          minHeight: "300px", // Altura mínima para mantener el tamaño consistente
          transition: "all 0.3s ease-in-out", // Transición suave
        }}
      >
        {activeTab === "chart" ? <TradingChart transactions = {transactions}/> : <CoinOverview />}
      </div>
    </div>
  );
};

export default TradingSection;
