import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <section className="contact-us-section">
      <div className="contact-background">
        <div className="contact-content">
          <h1 className="contact-title">
            AQUA<span className="contact-highlight">X</span>CHANGE
          </h1>
          <p className="contact-subtitle">Every DROP Makes a Difference</p>
          <button className="contact-button">PARTNER WITH US</button>
        </div>
        <div className="contact-info">
          <div className="info-address">
            <p>Drop x AquaXchange</p>
            <p>Carrer de Casanova 64</p>
            <p>Barcelona 08011</p>
          </div>
          <div className="info-contact">
            <p>+34 632 524 833</p>
            <p>partner@aquaxchange.com</p>
            <p>@aquaxchange</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
