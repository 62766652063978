import React from "react";

const MarketHeader = () => {
  return (
    <div
      style={{
        background: "#1f1f1f",
        padding: "15px 20px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#fff",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Información principal */}
      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        {/* Logo e información de la moneda */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div
            style={{
              background: "#ffa500",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "18px",
              color: "#fff",
            }}
          >
            A
          </div>
          <div>
            <h3 style={{ margin: 0 }}>AQUA/EUR</h3>
            <p style={{ margin: 0, color: "#aaa" }}>Aqua Exchange</p>
          </div>
        </div>

        {/* Precio actual */}
        <div style={{ fontSize: "24px", fontWeight: "bold", color: "#4caf50" }}>
          5.34
        </div>
        <div style={{ fontSize: "14px", color: "#4caf50" }}>+1.75%</div>
      </div>

      {/* Información adicional */}
      <div style={{ display: "flex", gap: "20px" }}>
        <div>
          <p style={{ margin: 0, fontSize: "12px", color: "#aaa" }}>24h High</p>
          <p style={{ margin: 0, fontSize: "14px" }}>5.60</p>
        </div>
        <div>
          <p style={{ margin: 0, fontSize: "12px", color: "#aaa" }}>24h Low</p>
          <p style={{ margin: 0, fontSize: "14px" }}>4.90</p>
        </div>
        <div>
          <p style={{ margin: 0, fontSize: "12px", color: "#aaa" }}>24h Quantity (AQUA)</p>
          <p style={{ margin: 0, fontSize: "14px" }}>10K</p>
        </div>
        <div>
          <p style={{ margin: 0, fontSize: "12px", color: "#aaa" }}>24h Total (EUR)</p>
          <p style={{ margin: 0, fontSize: "14px" }}>50K</p>
        </div>
      </div>

      {/* Etiquetas y opciones */}
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <span
          style={{
            background: "#333",
            borderRadius: "5px",
            padding: "5px 10px",
            fontSize: "12px",
            color: "#aaa",
          }}
        >
          AQUA Ecosystem
        </span>
        <span
          style={{
            background: "#333",
            borderRadius: "5px",
            padding: "5px 10px",
            fontSize: "12px",
            color: "#aaa",
          }}
        >
          Layer 1
        </span>
        <div style={{ display: "flex", gap: "10px" }}>
          <i className="fas fa-chart-line" style={{ color: "#fff" }}></i>
          <i className="fas fa-balance-scale" style={{ color: "#fff" }}></i>
          <i className="fas fa-cog" style={{ color: "#fff" }}></i>
        </div>
      </div>
    </div>
  );
};

export default MarketHeader;
