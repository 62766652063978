import React from 'react';
import './Benefits.css';
import communalImg from '../../assets/communal.jpg';
import realTimeImg from '../../assets/realtime.jpg';
import forecastingImg from '../../assets/forecasting.jpg';

const Benefits = () => {
  return (
    <section className="benefits-section">
      <h2>Benefits | Saving Every Drop</h2>
      <div className="benefits-cards">
        <div className="benefit-card">
          <img src={communalImg} alt="Communal Reinvestment" />
          <h3>Communal Reinvestment</h3>
          <p>
            50% of the profit from each Xchange of aqua credits traded is invested in local water saving projects such as desalination, carbon filtration, and water reuse.
          </p>
        </div>
        <div className="benefit-card">
          <img src={realTimeImg} alt="Real Time Consumption Awareness" />
          <h3>Real Time Consumption Awareness</h3>
          <p>
            Companies will track their consumption, sectoral consumption and partnering businesses consumption in order to spread water usage and drought awareness and impact in the community.
          </p>
        </div>
        <div className="benefit-card">
          <img src={forecastingImg} alt="Forecasting" />
          <h3>Forecasting</h3>
          <p>
            Businesses are able to better forecast their water usage through credit price actively fluctuating with reservoir capacity, rainfall, demand, and drought period timing.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
