import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Simulation from './pages/Simulation';
import MenuButtons from './components/menu/MenuButtons';
import './App.css';

function App() {
  const location = useLocation(); // Obtén la ruta actual

  // Aplica clases condicionales según la ruta
  const appBackgroundClass = location.pathname === '/simulation' ? 'simulationBackground' : 'appBackground';

  return (
    <div className={appBackgroundClass}>
      {location.pathname !== '/simulation' && <MenuButtons />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/simulation" element={<Simulation />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

function Root() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default Root;
