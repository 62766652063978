import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TradingChart = ({ transactions = [] }) => {
  if (!transactions || transactions.length === 0) {
    return (
      <div
        style={{
          background: "#1f1f1f",
          padding: "20px",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
          color: "#fff",
        }}
      >
        No transaction data available
      </div>
    );
  }

  // Extraer datos para el gráfico
  const labels = transactions.map((tx) => new Date(tx.date).toLocaleDateString());
  const dataPrices = transactions.map((tx) => tx.price);

  // Cálculo de medias móviles
  const calculateMovingAverage = (data, period) => {
    const movingAverage = [];
    for (let i = 0; i < data.length; i++) {
      if (i < period - 1) {
        movingAverage.push(null);
      } else {
        const sum = data.slice(i - period + 1, i + 1).reduce((acc, val) => acc + val, 0);
        movingAverage.push(sum / period);
      }
    }
    return movingAverage;
  };

  const movingAverage5 = calculateMovingAverage(dataPrices, 5);
  const movingAverage10 = calculateMovingAverage(dataPrices, 10);

  // Configuración de datos para el gráfico
  const data = {
    labels,
    datasets: [
      {
        label: "Price (EUR)",
        data: dataPrices,
        borderColor: "#00bcd4",
        backgroundColor: "rgba(0, 188, 212, 0.2)",
        borderWidth: 2,
        tension: 0.4, // Suaviza las líneas
      },
      {
        label: "MA5",
        data: movingAverage5,
        borderColor: "#ff4081",
        borderDash: [5, 5],
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "MA10",
        data: movingAverage10,
        borderColor: "#ffc107",
        borderDash: [10, 5],
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  // Opciones del gráfico
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          color: "#fff",
        },
      },
      title: {
        display: true,
        text: "Water Credits Price - 3 Months",
        color: "#fff",
        font: {
          size: 16,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#aaa",
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: "#aaa",
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
      },
    },
  };

  return (
    <div
      style={{
        background: "#1f1f1f",
        padding: "20px",
        borderRadius: "10px",
        height: "400px",
      }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default TradingChart;
