import React from 'react';
import './HeroSection.css';
import { useNavigate } from 'react-router-dom';


const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <section className="hero-section">
      <div className="hero-content">
        <div className="title-section">
          <h1 className="hero-title">AQUA</h1><h1 className="x">X</h1><h1 className="hero-title">CHANGE</h1>
          </div>
        <h2 className="hero-subtitle">
          Every Drop Counts : Trading for a Water-Wise Future
        </h2>
      </div>

      <div className="get-involved">
        <button className="get-involved-button" onClick={() => navigate('/simulation')}>GET INVOLVED</button>
      </div>


    </section>
  );
};

export default HeroSection;