import React from "react";

const MarketTrades = ({ transactions = [] }) => {
  return (
    <div
      style={{
        background: "#1f1f1f",
        color: "#fff",
        padding: "20px",
        borderRadius: "10px",
        fontFamily: "Arial, sans-serif",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        maxHeight: "300px", // Altura fija
        overflowY: "scroll", // Barra de desplazamiento vertical
      }}
    >
      {/* Título */}
      <h4
        style={{
          color: "#4caf50",
          textAlign: "center",
          marginBottom: "10px",
          borderBottom: "2px solid #4caf50",
          paddingBottom: "5px",
          fontSize: "18px",
        }}
      >
        Market Trades
      </h4>

      {/* Tabla */}
      {transactions.length > 0 ? (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={{ textAlign: "left", padding: "5px 10px" }}>Price (EUR)</th>
              <th style={{ textAlign: "left", padding: "5px 10px" }}>Quantity</th>
              <th style={{ textAlign: "left", padding: "5px 10px" }}>Date</th>
              <th style={{ textAlign: "left", padding: "5px 10px" }}>Time</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((trade, index) => (
              <tr
                key={index}
                style={{
                  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                }}
              >
                <td style={{ padding: "5px 10px" }}>{trade.price.toFixed(2)}</td>
                <td style={{ padding: "5px 10px" }}>{trade.quantity.toFixed(2)}</td>
                <td style={{ padding: "5px 10px" }}>{trade.date}</td>
                <td style={{ padding: "5px 10px" }}>{trade.time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p style={{ textAlign: "center", color: "#aaa" }}>No trades available</p>
      )}
    </div>
  );
};

export default MarketTrades;
