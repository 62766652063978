import React from 'react';
import './MissionVisionPurpose.css';
import mission from '../../assets/mission.jpg';
import vision from '../../assets/vision.jpeg';
import purpose from '../../assets/purpose.jpg';

const MissionVisionPurpose = () => {
  return (
    <section className="section-about">
      <h2>About Us</h2>
      <div className="about-cards">
        <div className="about-card">
          <h3>Mission</h3>
            <img src={mission}/>
          <p>
            Our mission is to build a credit exchange system based on the
            principles of water preservation
          </p>
        </div>
        <div className="about-card">
          <h3>Vision</h3>
          <img src={vision}/>
          <p>
            Our vision is to make Barcelona a water-conscious industrial
            metropolis
          </p>
        </div>
        <div className="about-card">
          <h3>Purpose</h3>
          <img src={purpose}/>
          <p>
            Our purpose is to provide an equal water sharing system for
            industrial manufacturers
          </p>
        </div>
      </div>
    </section>
  );
};

export default MissionVisionPurpose;
